import { Apollo } from "apollo-angular";
import { Injectable } from "@angular/core";

import { HttpClient } from "@angular/common/http";
import {
  checkIfAccountIsEnough,
  getAllCountries,
  productsFeaturesByUpdate,
  productsFeaturesRelated,
  productsFeaturesRandom,
  getBillsByUserStateAndPlace,
  progressByBillId,
  allCoins,
  findSearchCriteria,
  getTagsForMarketSearch,
  deepInTree,
  findProductFeaturesByCriteriaPublic,
  searchTags,
  sonTags,
  isBillAvailable,
  myCart,
  getMHUserByFK_Person,
  featureById,
  muyTicoCategories,
  getCuponsByGoogleAuth,
  validatePhone,
  ninja,
  billToPay,
  cuponByid,
  getPlaceById,
  downloadXML,
  downloadRespXML,
  deepInTreebyTags,
  findBillsByStateAndUser,
  getCategoryTagsByPlace,
} from "./graphql/querys";
import {
  disableShowGuide,
  userByUID,
  registerUser,
  registerPerson,
  firebaseAuth,
  updateAddress,
  updateAddressState,
  createAddress,
  sendCoinsToWallet,
  updateUserProfilePicture,
  createBillReview,
  createProductReview,
  createBillDetail,
  updateBillDetailQuantity,
  removeBillDetail,
  createSearchCriteria,
  deleteSearchCriteriaUser,
  payCart,
  addClickToProductFeature,
  createNotAvailableAddressToSend,
  subtractCupon,
  suscribeToNotification,
  updateUserPhone,
  editDetailsBill,
  selectBillAddress,
  payCartNative,
  suscribeToBill,
  updateDevice,
  saveDeviceToken,
  subscribeToPlace,
  registerClient,
  deleteBillDetail,
} from "./graphql/mutations";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  constructor(private apollo: Apollo, private http: HttpClient) {}

  ///////////////////////////////////////
  /////////////QUERIES//////////////////
  //////////////////////////////////////

  findPlace = (query) =>
    this.http.get(`https://photon.komoot.io/api?q=${query}&lang=en&limit=8`);

  findProductFeaturesByCriteriaPublic = (
    criteria,
    limit,
    offset,
    filters
  ) =>
    this.apollo.query<any>({
      query: findProductFeaturesByCriteriaPublic,
      variables: {
        criteria,
        limit,
        offset,
        filters,
        placeId: environment.placeId,
      },
    });

  deepInTree = (tag, limit, offset, filters) =>
    this.apollo.query<any>({
      query: deepInTree,
      variables: { tag, limit, offset, filters },
    });

  deepInTreebyTags = (tags, limit, offset, filters) =>
    this.apollo.query<any>({
      query: deepInTreebyTags,
      variables: { tags, limit, offset, filters },
    });

  searchTags = (criteria) =>
    this.apollo.query<any>({
      query: searchTags,

      variables: { criteria },
    });

  findSearchCriteria = (criteria, limit, offset, userId) =>
    this.apollo.query<any>({
      query: findSearchCriteria,
      variables: { criteria, limit, offset, userId, FK_Module: 3 },
    });

  progressByBillId = (id) =>
    this.apollo.query<any>({
      query: progressByBillId,
      variables: { id },
    });

  checkIfAccountIsEnough = (FK_Account, Total) =>
    this.apollo.query<any>({
      query: checkIfAccountIsEnough,
      variables: { FK_Account, Total },
    });

  getAllCountries = () =>
    this.apollo.query<any>({
      query: getAllCountries,
    });

  productsFeaturesByUpdate = (limit, offset) =>
    this.apollo.query<any>({
      query: productsFeaturesByUpdate,
      variables: {
        limit,
        offset,
        placeId: environment.placeId,
      },
    });

  productsFeaturesRelated = (tags, limit, offset) =>
    this.apollo.query<any>({
      query: productsFeaturesRelated,
      variables: {
        tags,
        limit,
        offset,
      },
    });

  getBillsByUserStateAndPlace = (states, limit, offset, placeId) =>
    this.apollo.query<any>({
      query: getBillsByUserStateAndPlace,

      variables: { states, limit, offset, placeId },
    });

  getTagsForMarketSearch = (criteria) =>
    this.apollo.query<any>({
      query: getTagsForMarketSearch,
      variables: { criteria },
    });

  sonTags = (id) =>
    this.apollo.query<any>({
      query: sonTags,
      variables: { id },
    });

  allCoins = () =>
    this.apollo.query<any>({
      query: allCoins,
    });

  isBillAvailable = (id) =>
    this.apollo.query<any>({
      query: isBillAvailable,
      variables: { id },
    });

  myCart = (placeId) =>
    this.apollo.query<any>({
      query: myCart,

      variables: { placeId },
    });

  featureById = (id) =>
    this.apollo.query<any>({
      query: featureById,
      variables: { id },
    });

  muyTicoCategories = () =>
    this.apollo.query<any>({
      query: muyTicoCategories,
    });

  getCuponsByGoogleAuth = () =>
    this.apollo.query<any>({
      query: getCuponsByGoogleAuth,
    });

  validatePhone = (phone, codeRegion) =>
    this.apollo.query<any>({
      query: validatePhone,
      variables: {
        phone,
        codeRegion,
      },
    });

  billToPay = (id) =>
    this.apollo.query<any>({
      query: billToPay,
      variables: id,
    });

  cuponByid = (id) =>
    this.apollo.query<any>({
      query: cuponByid,
      variables: id,
    });

  getPlaceById = (id) =>
    this.apollo.query<any>({
      query: getPlaceById,
      variables: { id },
    });

  downloadXML = (id) =>
    this.apollo.use("EB").query<any>({
      query: downloadXML,

      variables: { id },
    });

  downloadRespXML = (id) =>
    this.apollo.use("EB").query<any>({
      query: downloadRespXML,

      variables: { id },
    });

  findBillsByStateAndUser = (State_Id: number) =>
    this.apollo.query<any>({
      query: findBillsByStateAndUser,

      variables: { FK_Place: environment.placeId, State_Id },
    });

  getCategoryTagsByPlace = (FK_Place: number) =>
    this.apollo.query<any>({
      query: getCategoryTagsByPlace,
      variables: { FK_Place },
    });

  //////////////////////////////////////
  /////////////MUTATION/////////////////
  //////////////////////////////////////

  payCart = (cart) =>
    this.apollo.use("EB").mutate<any>({
      mutation: payCart,

      variables: { cart },
    });

  payCartNative = (cart) =>
    this.apollo.use("EB").mutate<any>({
      mutation: payCartNative,
      variables: { cart },
    });

  selectBillAddress = (billId, addressId) =>
    this.apollo.mutate<any>({
      mutation: selectBillAddress,
      variables: { billId, addressId },
    });

  getMHUserByFK_Person = (id) =>
    this.apollo.use("EB").query<any>({
      query: getMHUserByFK_Person,
      variables: { id },
    });

  suscribeToNotification = (notificationDevice) =>
    this.apollo.mutate<any>({
      mutation: suscribeToNotification,
      variables: { notificationDevice },
    });

  suscribeToBill = (notificationDevice) =>
    this.apollo.mutate<any>({
      mutation: suscribeToBill,
      variables: { notificationDevice },
    });

  removeBillDetail = (id) =>
    this.apollo.mutate<any>({
      mutation: removeBillDetail,

      variables: { id },
    });

  createBillDetail = (detail) =>
    this.apollo.mutate({
      mutation: createBillDetail,

      variables: { detail },
    });

  updateBillDetailQuantity = (id, quantity, total) =>
    this.apollo.mutate<any>({
      mutation: updateBillDetailQuantity,

      variables: { id, quantity, total },
    });

  deleteBillDetails = (details) =>
    this.apollo.mutate<any>({
      mutation: deleteBillDetail,

      variables: { details },
    });

  createProductReview = (review) =>
    this.apollo.mutate<any>({
      mutation: createProductReview,
      variables: { review },
    });

  createBillReview = (review) =>
    this.apollo.mutate<any>({
      mutation: createBillReview,
      variables: {
        review,
      },
    });

  userByUID = (uid) =>
    this.apollo.mutate<any>({
      mutation: userByUID,
      variables: { uid },
    });

  ninja = (uid) =>
    this.apollo.use("EB").query<any>({
      query: ninja,
      variables: { uid },
    });

  disableShowGuide = () =>
    this.apollo.mutate<any>({
      mutation: disableShowGuide,
    });

  registerUser = (user) =>
    this.apollo.mutate<any>({
      mutation: registerUser,
      variables: { user },
    });

  registerPerson = (user) =>
    this.apollo.mutate<any>({
      mutation: registerPerson,

      variables: { user },
    });

  registerClient = (user) =>
    this.apollo.mutate<any>({
      mutation: registerClient,
      variables: { user, placeId: environment.placeId },
    });

  firebaseAuth = (user) =>
    this.apollo.mutate<any>({
      mutation: firebaseAuth,
      variables: { user },
    });

  updateAddressState = (id, State_Address) =>
    this.apollo.mutate<any>({
      mutation: updateAddressState,
      variables: {
        id,
        State_Address,
      },
    });

  updateAddress = (id, address) =>
    this.apollo.mutate<any>({
      mutation: updateAddress,

      variables: { id, address },
    });

  newAddress = (address) =>
    this.apollo.mutate<any>({
      mutation: createAddress,

      variables: { address },
    });

  sendCoinsToWallet = (emittingAccountId, receptingAccountId, amount) =>
    this.apollo.mutate<any>({
      mutation: sendCoinsToWallet,
      variables: {
        srlorytuak: emittingAccountId,
        ktndxlufpy: receptingAccountId,
        whwjwtyypj: amount,
      },
    });

  updateUserProfilePicture = (Route_File, FirebaseId) =>
    this.apollo.mutate<any>({
      mutation: updateUserProfilePicture,
      variables: { Route_File, FirebaseId },
    });

  createNotAvailableAddressToSend = (notAvailable) =>
    this.apollo.mutate<any>({
      mutation: createNotAvailableAddressToSend,
      variables: { notAvailable },
    });

  updateUserPhone = (newPhone) =>
    this.apollo.mutate<any>({
      mutation: updateUserPhone,
      variables: { newPhone },
    });

  editDetailsBill = (billId, newDetails) =>
    this.apollo.mutate<any>({
      mutation: editDetailsBill,
      variables: { newDetails, billId },
    });

  createSearchCriteria = (criteria) =>
    this.apollo.mutate<any>({
      mutation: createSearchCriteria,
      variables: { criteria },
    });

  deleteSearchCriteriaUser = (id) =>
    this.apollo.mutate<any>({
      mutation: deleteSearchCriteriaUser,
      variables: { id },
    });

  subtractCupon = (cuponId) =>
    this.apollo.mutate<any>({
      mutation: subtractCupon,
      variables: { cuponId },
    });

  addClickToProductFeature = (id) =>
    this.apollo.mutate<any>({
      mutation: addClickToProductFeature,
      variables: { id },
    });

  updateDevice = (device) =>
    this.apollo.mutate<any>({
      mutation: updateDevice,
      variables: { device },
    });

  saveDeviceToken = (ctx, token) =>
    this.apollo.mutate<any>({
      mutation: saveDeviceToken,
      variables: { token },
    });

  subscribeToPlace = (ctx, FK_Place) =>
    this.apollo.use("Places").mutate<any>({
      mutation: subscribeToPlace,
      variables: { FK_Place },
    });
}
