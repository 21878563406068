import { Platform } from "@angular/cdk/platform";
import { MatSnackBar } from "@angular/material/snack-bar";
import { DeviceInfo } from "@capacitor/core";

export interface UI {
  device?: any;
  selectedColor?: string;
  install?: any;
  share?: boolean;
  mobile?: boolean;
  native?: boolean;
  sidenav?: any;
  loadingData: LoadingData;
  relatedApps?: any[];
  muyticoCarousel?: any;
  deviceInfo?: DeviceInfo;
}

export interface Device extends Platform {
  hash?: string;
  name?: string;
  userAgent?: string;
  vendor?: string;
}

export interface LoadingData {
  loading?: boolean;
  yProgress?: number;
}
export class DataAlert {
  message: string;
  snackType: string;
  snackBar: MatSnackBar;
}
