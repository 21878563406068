import { InMemoryCache } from "@apollo/client/core";
import { Apollo } from "apollo-angular";
import { NgModule, inject } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpLink } from "apollo-angular/http";
import { CookieService } from "ngx-cookie-service";
import { Auth } from "@angular/fire/auth";
import { Router } from "@angular/router";
import { ApolloLink, split } from "@apollo/client/core";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { getMainDefinition } from "@apollo/client/utilities";
import { HttpErrorResponse } from "@angular/common/http";
import { createClient } from "graphql-ws";

type ApolloException = {
  clientVersion: string;
  code: string;
  name: string;
  stacktrace: string[];
};

@NgModule({
  declarations: [],
  imports: [],
})
export class ApolloClientsModule {
  constructor(private apollo: Apollo, private httpLink: HttpLink) {
    const cookieService = inject(CookieService);
    const auth = inject(Auth);
    const router = inject(Router);

    const basicContext = setContext((operation, context) => ({
      headers: {
        Accept: "charset=utf-8",
      },
    }));

    const authContext = setContext(async (operation, context) => {
      let token = cookieService.get("user_token") ?? null;

      if (!token) {
        if (auth.currentUser) {
          const newToken = await auth.currentUser.getIdToken(true);
          const expirationDate: Date = new Date();
          expirationDate.setMinutes(expirationDate.getMinutes() + 59);
          cookieService.set("user_token", newToken, {
            expires: expirationDate,
            sameSite: "Lax",
          });

          token = cookieService.get("user_token");
          return { headers: { Authorization: `Bearer ${token}` } };
        }

        return {};
      }

      return { headers: { Authorization: `Bearer ${token}` } };
    });

    const errorLink = onError(({ networkError, graphQLErrors }) => {
      if (networkError) {
        const userIsOnline = navigator.onLine;
        const networkErrorMessage = networkError?.message ?? "";
        const networkErrorName = networkError?.name ?? "";

        if (
          networkErrorMessage.includes("0 Unknown Error") &&
          networkErrorName === "HttpErrorResponse" &&
          userIsOnline
        ) {
          router.navigate(["site-in-maintenance"], {
            state: { activatedByRouting: true },
          });
          return;
        }

        const httpError = networkError as HttpErrorResponse;
        if (
          httpError.error.errors[0]?.message?.includes(
            "Firebase ID token has expired"
          )
        ) {
          if (auth.currentUser) {
            auth.currentUser.getIdToken(true).then((token) => {
              const expirationDate: Date = new Date();
              expirationDate.setMinutes(expirationDate.getMinutes() + 59);
              cookieService.set("user_token", token, {
                expires: expirationDate,
                sameSite: "Lax",
              });
              return;
            });
          }
        }
      }

      graphQLErrors?.map((error) => {
        const exception = <ApolloException>error.extensions["exception"];
        if (
          exception.name === "NotFoundError" ||
          exception.name === "PrismaClientValidationError"
        ) {
          const currentRoute = router.routerState.snapshot.url;
          router.navigate(["/not-found"], {
            state: { activatedByRouting: true },
          });
          return;
        }
      });
    });

    // Define http and ws links to be able to select between them by operation:
    const http = this.httpLink.create({ uri: environment.api });
    const ws = new GraphQLWsLink(
      createClient({
        url: environment.api,
        connectionParams: () => {
          let token = cookieService.get("user_token") ?? null;

          if (!token) {
            return {};
          }
          return { headers: { Authorization: `Bearer ${token}` } };
        },
      })
    );

    const selectedLink = split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === "OperationDefinition" &&
          definition.operation === "subscription"
        );
      },
      ws,
      http
    );

    const link = ApolloLink.from([
      basicContext,
      authContext,
      errorLink,
      selectedLink,
    ]);

    this.apollo.createDefault({
      link,
      cache: new InMemoryCache(),
      defaultOptions: {
        watchQuery: {
          fetchPolicy: "network-only",
          errorPolicy: "ignore",
        },
        query: {
          fetchPolicy: "network-only",
          errorPolicy: "ignore",
        },
      },
    });

    this.apollo.createNamed("EB", {
      link,
      cache: new InMemoryCache(),
      defaultOptions: {
        watchQuery: {
          fetchPolicy: "network-only",
        },
        query: {
          fetchPolicy: "network-only",
        },
      },
    });
  }
}
