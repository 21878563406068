import { v1 as uuid } from "uuid";
import firebase from "firebase/compat/app";
import { Navigate } from "@ngxs/router-plugin";
import { ApiService } from "../services/api.service";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AngularFireStorage } from "@angular/fire/compat/storage";
import { finalize, tap } from "rxjs/operators";
import { trace } from "@angular/fire/compat/performance";
import { State, Action, StateContext, Selector, Store } from "@ngxs/store";
import { User } from "../interface/user";
import {
  SignOut,
  FirebaseAuth,
  UpdateUserSession,
  UserByUID,
  WatchIdToken,
  CreateAcount,
  RegisterPerson,
  GoogleLogin,
  Login,
  ResetPasswordEmail,
  UploadProfileImage,
  SaveImageRouteForProfile,
  UpdateProfileImage,
  AllCountries,
  ProductsFeaturesByUpdate,
  GetBillsByUserAndState,
  ProgressByBillId,
  CreateProductReview,
  CreateBillReview,
  AddToCart,
  ReduceToCart,
  UpdateAddress,
  CreateAddress,
  UpdateBillDetailQuantity,
  GetCoins,
  IsBillAvailable,
  MyCart,
  PayCart,
  UpdateAddressState,
  CreateNotAvailableAddressToSend,
  MoreProductsFeaturesByUpdate,
  ApproveOrder,
  DisableShowGuide,
  GetCuponsByGoogleAuth,
  SelectCupon,
  SubtractCupon,
  ProductsFeaturesRelated,
  MoreProductsFeaturesRelated,
  UpdateUserPhone,
  EditBillDetails,
  SelectAddress,
  LoginNative,
  NativeUser,
  PredictPlace,
  ThisPlace,
  NewUserLaunched,
  CreateAccountClient,
  ClearCart,
} from "./actions/user.actions";
import {
  UpdateFeaturesInSearch,
  UpdateSelectedFeature,
} from "./actions/product.actions";
import { Injectable } from "@angular/core";
import { SetLoading } from "./actions/ui.actions";
import { UIState } from "./ui.state";
import { UI } from "../interface/ui";
import { append, patch, removeItem, updateItem } from "@ngxs/store/operators";
import { AngularFireAnalytics } from "@angular/fire/compat/analytics";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";
import { AlertService } from "../services/alert.service";

@State<User>({
  name: "user",
  defaults: {
    ShowGuide: true,
    selectedCupon: null,
    loadMoreFeatures: false,
    moreFeaturesRelated: false,
    isGhost: "1",
    notAvailableBillDetails: [],
    isAuthenticated: false,
  },
})
@Injectable()
export class UserState {
  installPrompt: any;
  conn: any;

  @Selector()
  static me({ firebaseUser: { TavuelUser } }: User) {
    return TavuelUser;
  }

  @Selector([UIState])
  static deviceAdded(
    {
      firebaseUser: {
        TavuelUser: { NotificationDevices },
      },
    }: User,
    ui: UI
  ) {
    return NotificationDevices.find((d) => d.uuid === ui.deviceInfo.uuid)
      .State_NotificationDevice;
  }

  @Selector()
  static defaultCoin({
    firebaseUser: {
      TavuelUser: { DefaultCoin },
    },
  }: User) {
    return DefaultCoin;
  }

  @Selector()
  static token({ token }: User) {
    return token;
  }

  @Selector()
  static hash({ hash }: User) {
    return hash;
  }

  @Selector()
  static isGhost(state: User) {
    return state.isGhost;
  }

  @Selector()
  static ghost({ isGhost }: User) {
    return isGhost === "1";
  }

  @Selector()
  static firebaseUser({ firebaseUser }: User) {
    return firebaseUser;
  }

  @Selector()
  static meUser({ firebaseUser: { TavuelUser } }: User) {
    return TavuelUser;
  }

  @Selector()
  static isSudo({
    firebaseUser: {
      TavuelUser: { Sudo },
    },
  }: User) {
    return Sudo;
  }

  @Selector()
  static UID({ firebaseUser: { UID } }: User) {
    return UID;
  }

  @Selector()
  static countries({ countries }: User) {
    return countries;
  }

  @Selector()
  static addresses({
    firebaseUser: {
      TavuelUser: {
        Person: { Addresses },
      },
    },
  }: User) {
    return Addresses;
  }

  @Selector()
  static thisWeek({ thisWeek }: User) {
    return thisWeek;
  }

  @Selector()
  static cupons({ cupons }: User) {
    return cupons;
  }

  @Selector()
  static selectedCupon({ selectedCupon }: User) {
    return selectedCupon;
  }

  @Selector()
  static features({ features }: User) {
    return features;
  }

  @Selector()
  static featuresRelated({ featuresRelated }: User) {
    return featuresRelated;
  }

  @Selector()
  static moreFeaturesRelated({ moreFeaturesRelated }: User) {
    return moreFeaturesRelated;
  }

  @Selector()
  static featuresRandom({ featuresRandom }: User) {
    return featuresRandom;
  }

  @Selector()
  static FilteredBills({ FilteredBills }: User) {
    return FilteredBills;
  }

  @Selector()
  static loadMoreOrders({ loadMoreOrders }: User) {
    return loadMoreOrders;
  }

  @Selector()
  static billProgress({ billProgress }: User) {
    return billProgress;
  }

  @Selector()
  static queryPlaces({ queryPlaces: { features } }: User) {
    return features;
  }

  @Selector()
  static cart({
    firebaseUser: {
      TavuelUser: { Cart },
    },
  }: User) {
    return Cart;
  }

  @Selector()
  static items({
    firebaseUser: {
      TavuelUser: { Cart },
    },
  }: User) {
    return Cart.Detail.reduce((total, detail) => {
      total += detail.Quantity_BillDetail;
      return total;
    }, 0);
  }

  @Selector()
  static cartDetail({
    firebaseUser: {
      TavuelUser: { Cart },
    },
  }: User) {
    return Cart.Detail;
  }

  @Selector()
  static coins({
    coins,
    firebaseUser: {
      TavuelUser: { DefaultCoin },
    },
  }: User) {
    return coins
      .filter((coin) => coin.id !== "4")
      .map((coin) =>
        coin.id === DefaultCoin.id ? { ...coin, default: true } : coin
      );
  }

  @Selector()
  static allCoins({ coins }: User) {
    return coins;
  }

  @Selector()
  static appCoin({ coins: [coin] }: User) {
    return coin;
  }

  @Selector()
  static loadMoreFeatures({ loadMoreFeatures }: User) {
    return loadMoreFeatures;
  }

  @Selector()
  static notAvailableBillDetails({ notAvailableBillDetails }: User) {
    return notAvailableBillDetails;
  }

  @Selector()
  static notAvailableBillDetailsList({
    notAvailableBillDetails,
    firebaseUser: {
      TavuelUser: { Cart },
    },
  }: User) {
    const details = notAvailableBillDetails.map((detail) => detail.id);
    return Cart.Detail.filter((detail) => details.includes(detail.id));
  }

  @Selector()
  static notAvailable({
    notAvailableBillDetails,
    firebaseUser: {
      TavuelUser: { Cart },
    },
  }: User) {
    const available = notAvailableBillDetails.length > 0 ? true : false;
    return available;
  }

  @Selector()
  static notAvailableAddressToSend({
    firebaseUser: {
      TavuelUser: {
        Cart: { NotAvailableAddressToSend },
      },
    },
  }: User) {
    return NotAvailableAddressToSend;
  }

  @Selector()
  static ShowGuide({ ShowGuide }: User) {
    return ShowGuide;
  }

  @Selector()
  static thisPlace({ thisPlace }: User) {
    return thisPlace;
  }

  @Selector()
  static isLoggedIn(state: User): boolean {
    return state.isAuthenticated;
  }

  constructor(
    private api: ApiService,
    private store: Store,
    private _alertService: AlertService,
    public afAuth: AngularFireAuth,
    private storage: AngularFireStorage,
    private analytics: AngularFireAnalytics,
    private router: Router
  ) {}

  ngxsOnInit({ dispatch }: StateContext<User>) {
    this.afAuth.useDeviceLanguage();
    dispatch(new GetCoins());
    dispatch(new WatchIdToken());
  }
  /*
  openSnackBar = (message: string, button: string, config?: any) => {
    return this.snackBar.open(message, button, config).afterDismissed();
  };*/

  @Action(UpdateUserSession)
  UpdateUserSession(
    { patchState, getState }: StateContext<User>,
    { info }: UpdateUserSession
  ) {
    const { firebaseUser, isGhost, ShowGuide } = info;
    patchState({ firebaseUser, isGhost, ShowGuide });
    const User = getState();
    if (this.conn && User.firebaseUser && User.firebaseUser.Email) {
      this.conn.send({ User });
    }
  }

  @Action(WatchIdToken)
  watchIdToken({ dispatch }: StateContext<User>) {
    this.afAuth.useDeviceLanguage();
    return this.afAuth.idTokenResult.pipe(
      tap((idToken) => {
        if (idToken) {
          const {
            claims: { user_id },
            signInProvider,
          } = idToken;
          if (signInProvider === "anonymous") {
            const info = {
              token: "",
              isGhost: "1",
              ShowGuide: true,
              firebaseUser: { UID: user_id },
            };
            dispatch(new UpdateUserSession(info));
          } else {
            dispatch(new UserByUID(user_id));
          }
        } else {
          const info = {
            token: null,
            isGhost: "1",
            ShowGuide: true,
            firebaseUser: null,
          };
          dispatch(new UpdateUserSession(info));
        }
      })
    );
  }

  @Action(SelectAddress)
  selectAddress(
    { getState, patchState }: StateContext<User>,
    { AddressSelected }: SelectAddress
  ) {
    const { firebaseUser } = getState();
    return this.api
      .selectBillAddress(firebaseUser.TavuelUser.Cart.id, AddressSelected.id)
      .pipe(
        tap(({ data: { selected } }) => {
          this.analytics.logEvent("SelectAddress");
          if (selected) {
            patchState({
              firebaseUser: {
                ...firebaseUser,
                TavuelUser: {
                  ...firebaseUser.TavuelUser,
                  Cart: {
                    ...firebaseUser.TavuelUser.Cart,
                    Address: AddressSelected,
                  },
                },
              },
            });
          }
        }),
        trace("SelectAddress")
      );
  }

  @Action(GetBillsByUserAndState)
  getBillsByUserStateAndPlace(
    { patchState, getState }: StateContext<User>,
    { states, type }: any
  ) {
    if (type === 1) {
      patchState({ loadMoreOrders: false });
    }
    const { loadMoreOrders, FilteredBills } = getState();
    const limit = 4;
    const offset = !loadMoreOrders ? 0 : FilteredBills.length;
    return this.api
      .getBillsByUserStateAndPlace(states, limit, offset, environment.placeId)
      .pipe(
        tap(({ data: { bills } }) => {
          this.analytics.logEvent("GetBillsByUserAndState");
          const newBills =
            !loadMoreOrders && offset === 0
              ? bills
              : [...FilteredBills, ...bills];
          patchState({
            FilteredBills: newBills,
            loadMoreOrders:
              bills.length < limit || bills.length === 0 ? false : true,
          });
        }),
        trace("GetBillsByUserAndState")
      );
  }

  @Action(ProgressByBillId)
  progressByBillId(
    { patchState, getState }: StateContext<User>,
    { id }: ProgressByBillId
  ) {
    patchState({ billProgress: null });
    const user = getState();
    if (user) {
      return this.api.progressByBillId(id).pipe(
        tap(({ data: { billProgress } }) => {
          patchState({ billProgress });
          this.analytics.logEvent("ProgressByBillId");
        }),
        trace("ProgressByBillId")
      );
    }
  }

  @Action(LoginNative)
  loginNative({ getState }: StateContext<User>, { conn }: LoginNative) {
    this.conn = conn;
    const User = getState();
    if (User.firebaseUser) {
      conn.send({ User });
    }
  }

  @Action(NativeUser)
  NativeUser({}: StateContext<User>, { uid }: NativeUser) {
    return this.api.ninja(uid).pipe(
      tap(({ data: { ninja } }) => {
        this.afAuth.signInWithCustomToken(ninja);
      })
    );
  }

  @Action(UserByUID)
  userByUID({ dispatch }: StateContext<User>, { uid }: UserByUID) {
    return this.api.userByUID(uid).pipe(
      tap(
        ({ data: { firebaseUser } }) => {
          if (firebaseUser) {
            if (firebaseUser.TavuelUser) {
              const info = {
                firebaseUser,
                token: "",
                isGhost: "0",
                ShowGuide: firebaseUser.ShowGuide,
              };

              dispatch(new UpdateUserSession(info));
            } else {
              const info = {
                firebaseUser,
                token: "",
                isGhost: "0",
                ShowGuide: false,
              };
              dispatch(new UpdateUserSession(info));
              dispatch(
                new Navigate(["/profile/register"], { password: false })
              );
            }
          } else {
            const info = {
              firebaseUser: { UID: uid },
              token: "",
              isGhost: "1",
              ShowGuide: false,
            };
            dispatch(new UpdateUserSession(info));
          }
          dispatch(new MyCart());
        },
        ({ graphQLErrors }) => {
          const [{ message }] = graphQLErrors;
          this._alertService.openSnackBar(message, "error");
        }
      ),
      trace("UserByUID")
    );
  }

  @Action(AllCountries)
  allCountries({ patchState }: StateContext<User>) {
    return this.api.getAllCountries().pipe(
      tap(({ data: { countries } }) => {
        patchState({ countries });
        this.analytics.logEvent("AllCountries");
      }),
      trace("AllCountries")
    );
  }

  @Action(GetCuponsByGoogleAuth)
  getCuponsByGoogleAuth(
    { getState, setState, dispatch }: StateContext<User>,
    {}: GetCuponsByGoogleAuth
  ) {
    return this.api.getCuponsByGoogleAuth().pipe(
      tap(({ data: { cupons } }) => {
        if (cupons && cupons.length > 0) {
          setState(patch({ cupons }));
        } else {
          setState(patch({ cupons: null }));
        }
        this.analytics.logEvent("GetCuponsByGoogleAuth");
      }),
      trace("GetCuponsByGoogleAuth")
    );
  }

  @Action(SubtractCupon)
  subtractCupon({ getState, setState }: StateContext<User>, {}: SubtractCupon) {
    const { selectedCupon } = getState();
    return this.api.subtractCupon(selectedCupon.id).pipe(
      tap(
        ({ data: { valid } }) => {},
        () => {
          this.analytics.logEvent("SubtractCupon");
        }
      ),
      trace("SubtractCupon")
    );
  }

  @Action(SelectCupon)
  selectCupon(
    { patchState }: StateContext<User>,
    { selectedCupon }: SelectCupon
  ) {
    patchState({ selectedCupon });
  }

  @Action(CreateAcount)
  CreateAcount(
    { dispatch, setState, patchState }: StateContext<User>,
    { user }: CreateAcount
  ) {
    delete user.ConfirmPassword;
    return this.api.registerUser(user).pipe(
      tap(
        () => {
          dispatch(new Login({ email: user.Email, password: user.Password }));
          patchState({ isAuthenticated: true });
          this._alertService.openSnackBar("Cuenta creada");
          this.analytics.logEvent("CreateAcount");
          setState(patch({ newUser: true }));
        },
        (err) => {
          if (err.graphQLErrors) {
            const [{ message }] = err.graphQLErrors;
            this._alertService.openSnackBar(message, "error");
          }
        }
      ),
      trace("CreateAcount")
    );
  }
  @Action(CreateAccountClient)
  createAccountClient(
    { dispatch, setState }: StateContext<User>,
    { user }: CreateAccountClient
  ) {
    delete user.ConfirmPassword;
    return this.api.registerClient(user).pipe(
      tap(
        () => {
          dispatch(new Login({ email: user.Email, password: user.Password }));
          this._alertService.openSnackBar("Cuenta creada");
          this.analytics.logEvent("CreateAcount");
          setState(patch({ newUser: true }));
        },
        (err) => {
          if (err.graphQLErrors) {
            const [{ message }] = err.graphQLErrors;
            this._alertService.openSnackBar(message, "error");
          }
        }
      ),
      trace("CreateAcountClient")
    );
  }

  @Action(CreateProductReview)
  CreateProductReview(
    { getState, setState }: StateContext<User>,
    { review }: CreateProductReview
  ) {
    return this.api.createProductReview(review).pipe(
      tap(({ data: { newReview } }) => {
        this.analytics.logEvent("CreateProductReview");
        this._alertService.openSnackBar("Gracias por ayudarnos a mejorar");
      }),
      trace("CreateProductReview")
    );
  }

  @Action(CreateBillReview)
  CreateBillReview(
    { patchState, getState, setState }: StateContext<User>,
    { review }: CreateBillReview
  ) {
    return this.api.createBillReview(review).pipe(
      tap(
        ({ data: { newReview } }) => {
          if (newReview) {
            this.analytics.logEvent("CreateBillReview");
            setState(
              patch({
                FilteredBills: updateItem<any>(
                  (xBill) => xBill.id === review.FK_Bill,
                  patch({ Review: review })
                ),
              })
            );
          }
        },
        () => {
          this._alertService.openSnackBar(
            "Intenta enviar la calificación de nuevo",
            "error"
          );
        }
      ),
      trace("CreateBillReview")
    );
  }

  @Action(RegisterPerson)
  RegisterPerson(
    { setState, getState, dispatch }: StateContext<User>,
    { user }: RegisterPerson
  ) {
    const { firebaseUser } = getState();
    delete user.ConfirmPassword;
    return this.api.registerPerson(user).pipe(
      tap(
        ({
          data: {
            registerPerson: { TavuelUser },
          },
        }) => {
          if (TavuelUser) {
            this.analytics.logEvent("RegisterPerson");
            setState(patch({ newUser: true }));
            firebaseUser.TavuelUser = TavuelUser;
            firebaseUser.Phone_Number = TavuelUser.Person.Phone;
            dispatch(new Navigate(["/profile"]));
            setState(patch({ firebaseUser }));
            dispatch(new MyCart());
          }
        },
        (err) => {
          if (err.graphQLErrors) {
            const [{ message }] = err.graphQLErrors;
            this._alertService.openSnackBar(message, "error");
          }
        }
      ),
      trace("RegisterPerson")
    );
  }

  @Action(SignOut)
  signOut({ dispatch, setState }: StateContext<User>) {
    return this.afAuth.signOut().then(() => {
      this.analytics.logEvent("signOut");
      localStorage.removeItem("hasSeenGuide");
      setState({
        notAvailableBillDetails: [],
        isGhost: "1",
        loadMoreFeatures: false,
        moreFeaturesRelated: false,
        firebaseUser: null,
        isAuthenticated: false,
      });
      dispatch(new GetCoins());
      dispatch(new ProductsFeaturesByUpdate());
      if (this.conn) {
        this.conn.send("signOut");
      }
      this.router.navigate(["/profile/login"]);
    });
  }

  @Action(GoogleLogin)
  async googleLogin(
    { dispatch, patchState }: StateContext<User>,
    {}: GoogleLogin
  ) {
    const support = this.store.selectSnapshot(UIState.googleAuthSupport);
    const native = this.store.selectSnapshot(UIState.native);
    if (support) {
      if (native) {
        this.conn.send("errorGoogle");
      } else {
        await this.afAuth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
        return this.afAuth
          .signInWithPopup(new firebase.auth.GoogleAuthProvider())
          .then(
            (result) => {
              dispatch(new FirebaseAuth(result));
              patchState({ isAuthenticated: true });

              this._alertService.openSnackBar("Bienvenido");
            },
            (err) => {
              console.log(err);
              this._alertService.openSnackBar(
                "Intenta desde otro navegador o con nuestro inicio de sesión",
                "warn"
              );

              throw new Error(err);
            }
          );
      }
    } else {
      this._alertService.openSnackBar(
        "El inicio de sesión con Google, es soportado unicamente por navegadores como Chrome, Firefox o Safari, intenta con alguno",
        "warn"
      );
    }
  }

  @Action(DisableShowGuide)
  disableShowGuide(
    { getState, patchState, setState }: StateContext<User>,
    { ShowGuide }: DisableShowGuide
  ) {
    const { firebaseUser } = getState();
    if (firebaseUser && !ShowGuide) {
      return this.api.disableShowGuide().pipe(
        tap(() => {
          this.analytics.logEvent("DisableShowGuide");
          patchState({
            firebaseUser: { ...firebaseUser, ShowGuide },
            ShowGuide,
          });
        }),
        trace("DisableShowGuide")
      );
    }
    patchState({ ShowGuide: false });
  }

  @Action(FirebaseAuth)
  firebaseAuth(
    { dispatch }: StateContext<User>,
    { user, ghostUID }: FirebaseAuth
  ) {
    const {
      additionalUserInfo: { providerId, profile },
      user: { uid, email, emailVerified, phoneNumber, displayName, photoURL },
    } = user;
    let signInUser: any = {
      Email: email,
      Picture: photoURL,
      Name: displayName,
      Verified_Email: emailVerified,
      Phone_Number: phoneNumber,
      UID: uid,
      Provider_Id: providerId,
      GhostUID: ghostUID,
      AcceptTerms: true,
    };
    if (profile) {
      const { family_name, given_name, locale } = profile;
      signInUser = {
        ...signInUser,
        Locale: locale || navigator.language,
        Given_Name: given_name,
        Family_Name: family_name,
      };
    } else {
      signInUser = {
        ...signInUser,
        Locale: navigator.language,
      };
    }
    return this.api.firebaseAuth(signInUser).pipe(
      tap(
        () => {
          this.analytics.logEvent("DisableShowGuide");
          dispatch(new Navigate(["/profile"]));
        },
        () =>
          this._alertService.openSnackBar(
            "Error al autenticar al usuario",
            "error"
          )
      ),
      trace("FirebaseAuth")
    );
  }

  @Action(Login)
  async login(
    { dispatch, patchState }: StateContext<User>,
    { credentials }: Login
  ) {
    const ghostUID = this.store.selectSnapshot(UserState.UID);
    try {
      const userCredentials = await this.afAuth.signInWithEmailAndPassword(
        credentials.email,
        credentials.password
      );
      dispatch(new FirebaseAuth(userCredentials, ghostUID));
      patchState({ isAuthenticated: true });

      dispatch(new SetLoading(false, 0));
      this._alertService.openSnackBar("Bienvenido");
    } catch (er) {
      dispatch(new SetLoading(false, 0));
      this._alertService.openSnackBar(this.getError(er.code), "error");
    }
  }

  @Action(ResetPasswordEmail)
  ResetPasswordEmail(
    { dispatch }: StateContext<User>,
    { email }: ResetPasswordEmail
  ) {
    this.afAuth
      .sendPasswordResetEmail(email)
      .then(() => {
        dispatch(new SetLoading(false, 0));
        dispatch(new Navigate(["/profile/login"]));
        this._alertService.openSnackBar("Email enviado con éxito");
      })
      .catch(({ code }) => {
        dispatch(new SetLoading(false, 0));
        this._alertService.openSnackBar(this.getError(code), "error");
      });
  }

  @Action(UploadProfileImage)
  UploadProfileImage(
    { dispatch }: StateContext<User>,
    { file }: UploadProfileImage
  ) {
    const uuidImage = uuid();
    const FirebaseId = `people/${environment.bucket_place}${uuidImage}`;
    const fileRef = this.storage.ref(FirebaseId);
    const uploadTask = this.storage.upload(FirebaseId, file);
    return uploadTask.snapshotChanges().pipe(
      finalize(() =>
        dispatch(
          new SaveImageRouteForProfile(fileRef.getDownloadURL(), FirebaseId)
        )
      ),
      trace("UploadProfileImage")
    );
  }

  @Action(SaveImageRouteForProfile)
  SaveImageRouteForProfile(
    { dispatch, setState }: StateContext<User>,
    { fileRef, FirebaseId }: SaveImageRouteForProfile
  ) {
    return fileRef.pipe(
      tap(
        (url) => dispatch(new UpdateProfileImage(url, FirebaseId)),
        () => {
          this.analytics.logEvent("SaveImageRouteForProfile");
          this._alertService.openSnackBar("Error al salvar la imagen", "error");
          this.getNewToken(setState);
        }
      ),
      trace("SaveImageRouteForProfile")
    );
  }

  @Action(UpdateProfileImage)
  UpdateProfileImage(
    { patchState, getState, setState, dispatch }: StateContext<User>,
    { Route_File, FirebaseId }: UpdateProfileImage
  ) {
    return this.api.updateUserProfilePicture(Route_File, FirebaseId).pipe(
      tap(
        ({ data: { updated } }) => {
          if (updated) {
            this.analytics.logEvent("UpdateProfileImage");
            const { firebaseUser } = getState();
            firebaseUser.TavuelUser.Person.ProfileImage = updated;
            patchState({
              firebaseUser,
            });

            const info = {
              firebaseUser,
              token: "",
              isGhost: "0",
              ShowGuide: firebaseUser.ShowGuide,
            };
            dispatch(new UpdateUserSession(info));
          }
        },
        () => this.getNewToken(setState)
      ),
      trace("UpdateProfileImage")
    );
  }

  @Action(ProductsFeaturesByUpdate)
  productsFeaturesByUpdate({
    patchState,
    getState,
    setState,
  }: StateContext<User>) {
    return this.api.productsFeaturesByUpdate(10, 0).pipe(
      tap(({ data: { features } }) => {
        const { firebaseUser } = getState();
        patchState({ features, loadMoreFeatures: true });
        if (
          firebaseUser &&
          firebaseUser.TavuelUser &&
          firebaseUser.TavuelUser.Cart &&
          firebaseUser.TavuelUser.Cart.Detail &&
          firebaseUser.TavuelUser.Cart.Detail.length > 0
        ) {
          this.analytics.logEvent("ProductsFeaturesByUpdate");
          features.forEach((f) => {
            let itemInCar = firebaseUser.TavuelUser.Cart.Detail.find(
              (d) => d.ProductFeature.id === f.id
            );
            if (itemInCar) {
              setState(
                patch({
                  features: updateItem<any>(
                    (xF) => xF.id === itemInCar.ProductFeature.id,
                    patch({ isAdded: itemInCar.Quantity_BillDetail })
                  ),
                })
              );
            }
          });
        }
      }),
      trace("ProductsFeaturesByUpdate")
    );
  }

  @Action(MoreProductsFeaturesByUpdate)
  MoreProductsFeaturesByUpdate({
    patchState,
    getState,
    setState,
  }: StateContext<User>) {
    const state = getState();
    return this.api.productsFeaturesByUpdate(10, state.features.length).pipe(
      tap(({ data: { features } }) => {
        const { firebaseUser } = getState();
        patchState({
          features: state.features.concat(features),
          loadMoreFeatures: features.length === 10,
        });
        if (
          firebaseUser &&
          firebaseUser.TavuelUser &&
          firebaseUser.TavuelUser.Cart &&
          firebaseUser.TavuelUser.Cart.Detail &&
          firebaseUser.TavuelUser.Cart.Detail.length > 0
        ) {
          this.analytics.logEvent("MoreProductsFeaturesByUpdate");
          features.forEach((f) => {
            let itemInCar = firebaseUser.TavuelUser.Cart.Detail.find(
              (d) => d.ProductFeature.id === f.id
            );
            if (itemInCar) {
              setState(
                patch({
                  features: updateItem<any>(
                    (xF) => xF.id === f.id,
                    patch({ isAdded: itemInCar.Quantity_BillDetail })
                  ),
                })
              );
            }
          });
        }
      }),
      trace("MoreProductsFeaturesByUpdate")
    );
  }

  @Action(ProductsFeaturesRelated)
  productsFeaturesRelated(
    { patchState, getState, setState }: StateContext<User>,
    { tags }: ProductsFeaturesRelated
  ) {
    return this.api.productsFeaturesRelated(tags, 15, 0).pipe(
      tap(({ data: { featuresRelated } }) => {
        const { firebaseUser } = getState();
        patchState({
          featuresRelated: featuresRelated,
          moreFeaturesRelated: featuresRelated && featuresRelated.length < 15,
        });
        this.analytics.logEvent("ProductsFeaturesRelated");
        if (
          firebaseUser &&
          firebaseUser.TavuelUser &&
          firebaseUser.TavuelUser.Cart &&
          firebaseUser.TavuelUser.Cart.Detail &&
          firebaseUser.TavuelUser.Cart.Detail.length > 0 &&
          featuresRelated
        ) {
          featuresRelated.forEach((f) => {
            let itemInCar = firebaseUser.TavuelUser.Cart.Detail.find(
              (d) => d.ProductFeature.id === f.id
            );
            if (itemInCar) {
              setState(
                patch({
                  featuresRelated: updateItem<any>(
                    (xF) => xF.id === itemInCar.id,
                    patch({ isAdded: itemInCar.Quantity_BillDetail })
                  ),
                })
              );
            }
          });
        }
      }),
      trace("ProductsFeaturesByUpdate")
    );
  }

  @Action(MoreProductsFeaturesRelated)
  moreProductsFeaturesRelated(
    { patchState, getState, setState }: StateContext<User>,
    { tags }: MoreProductsFeaturesRelated
  ) {
    const state = getState();
    return this.api
      .productsFeaturesRelated(tags, 10, state.featuresRelated.length)
      .pipe(
        tap(({ data: { featuresRelated } }) => {
          const { firebaseUser } = getState();
          patchState({
            featuresRelated: featuresRelated
              ? state.featuresRelated.concat(featuresRelated)
              : state.featuresRelated,
            moreFeaturesRelated:
              featuresRelated && featuresRelated.length === 10,
          });
          this.analytics.logEvent("MoreProductsFeaturesRelated");
          if (
            firebaseUser &&
            firebaseUser.TavuelUser &&
            firebaseUser.TavuelUser.Cart &&
            firebaseUser.TavuelUser.Cart.Detail &&
            firebaseUser.TavuelUser.Cart.Detail.length > 0 &&
            featuresRelated
          ) {
            featuresRelated.forEach((f) => {
              let itemInCar = firebaseUser.TavuelUser.Cart.Detail.find(
                (d) => d.ProductFeature.id === f.id
              );
              if (itemInCar) {
                setState(
                  patch({
                    featuresRelated: updateItem<any>(
                      (xF) => xF.id === itemInCar.id,
                      patch({ isAdded: itemInCar.Quantity_BillDetail })
                    ),
                  })
                );
              }
            });
          }
        }),
        trace("MoreProductsFeaturesByUpdate")
      );
  }

  @Action(AddToCart)
  addToCart(
    { patchState, getState, dispatch, setState }: StateContext<User>,
    { detail }: AddToCart
  ) {
    let {
      firebaseUser: { TavuelUser },
    } = getState();
    if (!TavuelUser) {
      this._alertService.openSnackBar(
        "Intente completar su cuenta primero",
        "warn"
      );
      return;
    }
    let { Cart, id: FK_User } = TavuelUser;
    let { notAvailableBillDetails } = getState();
    let exist = Cart.Detail.find(
      (fDetail) => fDetail.ProductFeature.id === detail.id
    );
    if (!exist) {
      const newDetail = {
        FK_ProductFeature: detail.id,
        FK_Bill: Cart.id,
        FK_BillDetailState: 1,
        FK_UnitOfMeasurement:
          detail.Product.InventoryDetail.UnitOfMeasurement.id, // Check this!
        Quantity_BillDetail: 1,
        SubTotal_BillDetail: detail.ProductPrice.Price_ProductPrice,
        Total_BillDetail: detail.ProductPrice.Price_ProductPrice,
      };
      return this.api.createBillDetail(newDetail).pipe(
        tap<any>(({ data: { billDetail } }) => {
          this.analytics.logEvent("AddToCart", {
            FK_User,
            FK_ProductFeature: detail.id,
          });
          dispatch(new UpdateFeaturesInSearch(detail.id, 1));
          dispatch(new UpdateSelectedFeature(detail.id, 1));
          const { features, featuresRelated } = getState();
          if (features && features.length > 0) {
            setState(
              patch({
                features: updateItem<any>(
                  (xF) => xF.id === detail.id,
                  patch({ isAdded: 1 })
                ),
              })
            );
          }
          if (featuresRelated && featuresRelated.length > 0) {
            setState(
              patch({
                featuresRelated: updateItem<any>(
                  (xF) => xF.id === detail.id,
                  patch({ isAdded: 1 })
                ),
              })
            );
          }
          setState(
            patch({
              firebaseUser: patch({
                TavuelUser: patch({
                  Cart: patch({ Detail: append([billDetail]) }),
                }),
              }),
            })
          );
        })
      );
    } else {
      const quantity = exist.Quantity_BillDetail + 1;
      const total = detail.ProductPrice.Price_ProductPrice * quantity;
      return this.api.updateBillDetailQuantity(exist.id, quantity, total).pipe(
        tap(({ data: { billDetail } }) => {
          this.analytics.logEvent("UpdateBillDetailQuantity", {
            FK_User,
            FK_ProductFeature: detail.id,
          });
          if (billDetail) {
            const {
              Quantity_BillDetail,
              SubTotal_BillDetail,
              Total_BillDetail,
              ProductFeature: { Quantity_ProductFeature },
            } = billDetail;
            setState(
              patch({
                firebaseUser: patch({
                  TavuelUser: patch({
                    Cart: patch({
                      Detail: updateItem<any>(
                        (d) => d.id === billDetail.id,
                        patch({
                          SubTotal_BillDetail,
                          Quantity_BillDetail,
                          Total_BillDetail,
                          ProductFeature: patch({ Quantity_ProductFeature }),
                        })
                      ),
                    }),
                  }),
                }),
              })
            );
            if (
              billDetail.Quantity_BillDetail >
              billDetail.ProductFeature.Quantity_ProductFeature
            ) {
              const newNotAvailableBillDetails = notAvailableBillDetails
                ? [...notAvailableBillDetails, billDetail]
                : [billDetail];
              patchState({
                notAvailableBillDetails: newNotAvailableBillDetails,
              });
            }
          }
          const { features, featuresRelated } = getState();
          if (features && features.length > 0) {
            setState(
              patch({
                features: updateItem<any>(
                  (xF) => xF.id === detail.id,
                  patch({ isAdded: quantity })
                ),
              })
            );
          }
          if (featuresRelated && featuresRelated.length > 0) {
            setState(
              patch({
                featuresRelated: updateItem<any>(
                  (xF) => xF.id === detail.id,
                  patch({ isAdded: quantity })
                ),
              })
            );
          }
          dispatch(new UpdateFeaturesInSearch(detail.id, quantity));
          dispatch(new UpdateSelectedFeature(detail.id, quantity));
        })
      );
    }
  }

  @Action(ReduceToCart)
  reduceToCart(
    { patchState, getState, dispatch, setState }: StateContext<User>,
    { detail }: ReduceToCart
  ) {
    const {
      firebaseUser: {
        TavuelUser: { Cart, id: FK_User },
      },
    } = getState();
    const { notAvailableBillDetails } = getState();
    const exist = Cart.Detail.find(
      (fDetail) => fDetail.ProductFeature.id === detail.id
    );
    if (exist) {
      if (exist.Quantity_BillDetail <= 1) {
        return this.api.removeBillDetail(exist.id).pipe(
          tap(({ data: { billDetail } }) => {
            this.analytics.logEvent("RemoveFromCart", {
              FK_User,
              FK_ProductFeature: detail.id,
            });
            if (billDetail) {
              setState(
                patch({
                  firebaseUser: patch({
                    TavuelUser: patch({
                      Cart: patch({
                        Detail: removeItem<any>(
                          (d) => d.ProductFeature.id === detail.id
                        ),
                      }),
                    }),
                  }),
                })
              );
              const {
                firebaseUser: {
                  TavuelUser: { Cart },
                },
              } = getState();
              if (
                Cart.Detail.length === 0 &&
                window.location.pathname === "/cart"
              ) {
                dispatch(new Navigate(["home"]));
              }
              const { features, featuresRelated } = getState();
              if (features && features.length > 0) {
                setState(
                  patch({
                    features: updateItem<any>(
                      (xF) => xF.id === detail.id,
                      patch({ isAdded: null })
                    ),
                  })
                );
              }
              if (featuresRelated && featuresRelated.length > 0) {
                setState(
                  patch({
                    featuresRelated: updateItem<any>(
                      (xF) => xF.id === detail.id,
                      patch({ isAdded: null })
                    ),
                  })
                );
              }
              dispatch(new UpdateFeaturesInSearch(detail.id, null));
              dispatch(new UpdateSelectedFeature(detail.id, null));
            }
          })
        );
      }
      const quantity = exist.Quantity_BillDetail - 1;
      const total = detail.ProductPrice.Price_ProductPrice * quantity;
      return this.api.updateBillDetailQuantity(exist.id, quantity, total).pipe(
        tap(({ data: { billDetail } }) => {
          this.analytics.logEvent("ReduceToCart", {
            FK_User,
            FK_ProductFeature: detail.id,
          });
          if (billDetail) {
            const {
              Quantity_BillDetail,
              SubTotal_BillDetail,
              Total_BillDetail,
              ProductFeature: { Quantity_ProductFeature },
            } = billDetail;
            setState(
              patch({
                firebaseUser: patch({
                  TavuelUser: patch({
                    Cart: patch({
                      Detail: updateItem<any>(
                        (d) => d.id === billDetail.id,
                        patch({
                          SubTotal_BillDetail,
                          Quantity_BillDetail,
                          Total_BillDetail,
                          ProductFeature: patch({ Quantity_ProductFeature }),
                        })
                      ),
                    }),
                  }),
                }),
              })
            );
            const notAvailable = notAvailableBillDetails.find(
              (xBillDetail) => xBillDetail.id === billDetail.id
            );
            if (
              notAvailable &&
              billDetail.Quantity_BillDetail <=
                billDetail.ProductFeature.Quantity_ProductFeature
            ) {
              const newNotAvailableBillDetails = notAvailableBillDetails.filter(
                (xBillDetail) => xBillDetail.id !== billDetail.id
              );
              patchState({
                notAvailableBillDetails: newNotAvailableBillDetails,
              });
            }
          }
          const { features, featuresRelated } = getState();
          if (features && features.length > 0) {
            setState(
              patch({
                features: updateItem<any>(
                  (xF) => xF.id === detail.id,
                  patch({ isAdded: quantity })
                ),
              })
            );
          }
          if (featuresRelated && featuresRelated.length > 0) {
            setState(
              patch({
                featuresRelated: updateItem<any>(
                  (xF) => xF.id === detail.id,
                  patch({ isAdded: quantity })
                ),
              })
            );
          }
          dispatch(new UpdateFeaturesInSearch(detail.id, quantity));
          dispatch(new UpdateSelectedFeature(detail.id, quantity));
        })
      );
    }
  }

  @Action(ClearCart)
  clearCart({ getState, setState }: StateContext<User>) {
    const {
      firebaseUser: {
        TavuelUser: { Cart, id: FK_User },
      },
    } = getState();
    const details = Cart.Detail.map((detail) => detail.id);

    return this.api.deleteBillDetails(details).pipe(
      tap(() => {
        this.analytics.logEvent("ClearCart", { FK_User });
        setState((state) => ({
          ...state,
          firebaseUser: {
            ...state.firebaseUser,
            TavuelUser: {
              ...state.firebaseUser.TavuelUser,
              Cart: {
                ...state.firebaseUser.TavuelUser.Cart,
                Detail: [],
              },
            },
          },
        }));

        const { features, featuresRelated } = getState();

        if (features && features.length > 0) {
          setState((state) => ({
            ...state,
            features: features.map((f) => ({ ...f, isAdded: null })),
          }));
        }

        if (featuresRelated && featuresRelated.length > 0) {
          setState((state) => ({
            ...state,
            featuresRelated: featuresRelated.map((f) => ({
              ...f,
              isAdded: null,
            })),
          }));
        }
      })
    );
  }

  @Action(UpdateAddress)
  updateAddress(
    { setState }: StateContext<User>,
    { id, address }: UpdateAddress
  ) {
    return this.api.updateAddress(id, address).pipe(
      tap(({ data: { updatedAddress } }) => {
        this.analytics.logEvent("UpdateAddress");
        setState(
          patch({
            firebaseUser: patch({
              TavuelUser: patch({
                Person: patch({
                  Addresses: updateItem((a) => a.id === id, updatedAddress),
                }),
              }),
            }),
          })
        );
      }),
      trace("UpdateAddress")
    );
  }

  @Action(CreateAddress)
  createAddress({ setState }: StateContext<User>, { address }: CreateAddress) {
    return this.api.newAddress(address).pipe(
      tap(({ data: { addressResult } }) => {
        if (addressResult) {
          this.analytics.logEvent("CreateAddress");
          setState(
            patch({
              firebaseUser: patch({
                TavuelUser: patch({
                  Person: patch({ Addresses: append([addressResult]) }),
                }),
              }),
            })
          );
        }
      }),
      trace("CreateAddress")
    );
  }

  @Action(UpdateBillDetailQuantity)
  updateBillDetailQuantity(
    { getState, setState }: StateContext<User>,
    { detail, newQuantity }: UpdateBillDetailQuantity
  ) {
    const {
      firebaseUser: {
        TavuelUser: { Cart, id: FK_User },
      },
      notAvailableBillDetails,
    } = getState();
    const exist = Cart.Detail.find((fDetail) => fDetail.id === detail.id);
    const numberQuantity = Number(newQuantity);
    if (exist) {
      const total =
        detail.ProductFeature.ProductPrice.Price_ProductPrice * numberQuantity;
      return this.api
        .updateBillDetailQuantity(exist.id, numberQuantity, total)
        .pipe(
          tap(({ data: { billDetail } }) => {
            this.analytics.logEvent("UpdateBillDetailQuantity", {
              FK_User,
              FK_ProductFeature: exist.id,
            });
            if (billDetail) {
              const {
                SubTotal_BillDetail,
                Quantity_BillDetail,
                Total_BillDetail,
                ProductFeature: { Quantity_ProductFeature },
              } = billDetail;
              setState(
                patch({
                  firebaseUser: patch({
                    TavuelUser: patch({
                      Cart: patch({
                        Detail: updateItem<any>(
                          (d) => d.id === billDetail.id,
                          patch({
                            SubTotal_BillDetail,
                            Quantity_BillDetail,
                            Total_BillDetail,
                            ProductFeature: patch({ Quantity_ProductFeature }),
                          })
                        ),
                      }),
                    }),
                  }),
                })
              );
              const notAvailable = notAvailableBillDetails.find(
                (xBillDetail) => xBillDetail.id === billDetail.id
              );
              if (
                notAvailable &&
                billDetail.Quantity_BillDetail <=
                  billDetail.ProductFeature.Quantity_ProductFeature
              ) {
                const newNotAvailableBillDetails =
                  notAvailableBillDetails.filter(
                    (xBillDetail) => xBillDetail.id !== billDetail.id
                  );
                setState(
                  patch({ notAvailableBillDetails: newNotAvailableBillDetails })
                );
              } else if (
                billDetail.Quantity_BillDetail >
                billDetail.ProductFeature.Quantity_ProductFeature
              ) {
                const newNotAvailableBillDetails = notAvailableBillDetails
                  ? [...notAvailableBillDetails, billDetail]
                  : [billDetail];
                setState(
                  patch({ notAvailableBillDetails: newNotAvailableBillDetails })
                );
              }
            }
          })
        );
    }
  }

  @Action(GetCoins)
  getCoins({ patchState }: StateContext<User>) {
    return this.api.allCoins().pipe(
      tap(
        ({ data: { coins } }) => {
          if (coins) {
            this.analytics.logEvent("GetCoins");
            patchState({ coins });
          }
        },
        () => {
          this._alertService.openSnackBar("Error al obtener las monedas");
        }
      ),
      trace("GetCoins")
    );
  }

  @Action(IsBillAvailable)
  isBillAvailable(
    { patchState, getState, setState }: StateContext<User>,
    { id }: IsBillAvailable
  ) {
    const { firebaseUser } = getState();
    return this.api.isBillAvailable(id).pipe(
      tap(({ data: { details } }) => {
        if (details) {
          patchState({ notAvailableBillDetails: details, firebaseUser });
          details.forEach((element) => {
            const detailSearch = firebaseUser.TavuelUser.Cart.Detail.find(
              (d) => d.id === element.id
            );
            if (detailSearch) {
              const {
                Quantity_BillDetail,
                ProductFeature: { Quantity_ProductFeature },
              } = element;
              setState(
                patch({
                  firebaseUser: patch({
                    TavuelUser: patch({
                      Cart: patch({
                        Detail: updateItem<any>(
                          (d) => d.id === element.id,
                          patch({
                            Quantity_BillDetail,
                            ProductFeature: patch({
                              Quantity_ProductFeature,
                            }),
                          })
                        ),
                      }),
                    }),
                  }),
                })
              );
            }
          });
        } else {
          patchState({ notAvailableBillDetails: [] });
        }
        this.analytics.logEvent("UpdateCartOfInfo");
      }),
      trace("UpdateCartOfInfo")
    );
  }

  @Action(MyCart)
  myCart({ getState, dispatch, setState }: StateContext<User>) {
    const { isGhost } = getState();
    if (isGhost === "1") {
      return dispatch(new Navigate(["home"]));
    }

    return this.api.myCart(environment.placeId).pipe(
      tap(({ data: { myCart } }) => {
        setState(
          patch({
            firebaseUser: patch({ TavuelUser: patch({ Cart: myCart }) }),
          })
        );
        this.analytics.logEvent("MyCart");
        dispatch(new IsBillAvailable(myCart.id));
      }),
      trace("MyCart")
    );
  }

  @Action(PayCart)
  payCart({ dispatch, getState }: StateContext<User>, { cart }: PayCart) {
    return this.api.payCart(cart).pipe(
      tap(
        () => {
          dispatch(new Navigate(["home"]));
          this.analytics.logEvent("PayCart");
          dispatch(new MyCart());
        },
        (err) => {
          console.log(err);
        }
      ),
      trace("payCart")
    );
  }

  @Action(UpdateAddressState)
  UpdateAddressState(
    { getState, setState }: StateContext<User>,
    { id, State_Address }: UpdateAddressState
  ) {
    return this.api.updateAddressState(id, State_Address).pipe(
      tap(({ data: { address } }) => {
        if (address && State_Address === 2) {
          this.analytics.logEvent("UpdateAddressState");
          setState(
            patch({
              firebaseUser: patch({
                TavuelUser: patch({
                  Person: patch({
                    Addresses: removeItem<any>((a) => a.id === address.id),
                  }),
                }),
              }),
            })
          );
        }
      }),
      trace("UpdateAddressState")
    );
  }

  @Action(CreateNotAvailableAddressToSend)
  CreateNotAvailableAddressToSend(
    { getState, setState }: StateContext<User>,
    { notAvailable }: CreateNotAvailableAddressToSend
  ) {
    return this.api.createNotAvailableAddressToSend(notAvailable).pipe(
      tap(({ data: { inserted } }) => {
        this.analytics.logEvent("CreateNotAvailableAddressToSend");
        setState(
          patch({
            firebaseUser: patch({
              TavuelUser: patch({
                Cart: patch({
                  NotAvailableAddressToSend: append([
                    { ...inserted, ...notAvailable },
                  ]),
                }),
              }),
            }),
          })
        );
      }),
      trace("CreateNotAvailableAddressToSend")
    );
  }

  @Action(ApproveOrder)
  approveOrder(
    { getState, patchState }: StateContext<User>,
    { firebaseId }: ApproveOrder
  ) {
    const { FilteredBills } = getState();
    const newBills = FilteredBills.filter(
      (bill) => bill.FirebaseId_Bill !== firebaseId
    );
    patchState({ FilteredBills: newBills });
  }

  @Action(UpdateUserPhone)
  updateUserPhone(
    { getState, setState }: StateContext<User>,
    { newPhone }: UpdateUserPhone
  ) {
    return this.api.updateUserPhone(newPhone).pipe(
      tap(
        ({ data: { edited } }) => {
          this.analytics.logEvent("UpdateUserPhone");
          if (edited) {
            setState(
              patch({ firebaseUser: patch({ Phone_Number: newPhone }) })
            );
            this._alertService.openSnackBar(
              "Número de teléfono actualizado.",
              "error"
            );
          }
        },
        (err) => {
          this._alertService.openSnackBar(
            "Error al actualizar el número de teléfono, pertenece a otro usuario.",
            "error"
          );
        }
      )
    );
  }

  @Action(EditBillDetails)
  editBillDetails(
    {}: StateContext<User>,
    { billId, newDetails }: EditBillDetails
  ) {
    return this.api.editDetailsBill(billId, newDetails).pipe(
      tap(() => this.analytics.logEvent("UpdateUserPhone")),
      trace("EditBillDetails")
    );
  }

  @Action(PredictPlace)
  PredictPlace({ setState }: StateContext<User>, { query }: PredictPlace) {
    return this.api
      .findPlace(query)
      .pipe(tap((queryPlaces) => setState(patch({ queryPlaces }))));
  }

  @Action(ThisPlace)
  ThisPlace({ setState }: StateContext<User>) {
    return this.api.getPlaceById(environment.placeId).pipe(
      tap(({ data: { place } }) => {
        setState(patch({ thisPlace: place }));
        this.analytics.logEvent("ThisPlace");
      }),
      trace("ThisPlace")
    );
  }

  getError = (error) => {
    return {
      "auth/user-not-found":
        "No existe ningún registro de usuario que corresponda al identificador proporcionado.",
      "auth/wrong-password": "La contraseña no es válida.",
      "auth/too-many-requests":
        "El acceso desactivado temporalmente por muchos intentos fallidos login. Puede restaurarlo restableciendo su contraseña o puede intentarlo más tarde.",
      "auth/argument-error": "El correo electronico debe ser valido",
    }[error];
  };

  getNewToken = async (setState) => {
    this._alertService.openSnackBar(
      "Había expirado tu sesión, vuelve a intentarlo"
    );
    const user = await this.afAuth.currentUser;
    const token = await user.getIdToken(true);
    setState(patch({ token }));
  };

  registerMessage = async (setState) => {
    this._alertService.openSnackBar(
      "Había expirado tu sesión, vuelve a intentarlo"
    );
    const user = await this.afAuth.currentUser;
    const token = await user.getIdToken(true);
    setState(patch({ token }));
  };

  @Action(NewUserLaunched)
  NewUserLaunched({ setState }: StateContext<User>) {
    setState(patch({ newUser: false }));
  }
}
