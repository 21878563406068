import { Observable } from "rxjs";
import {
  FirebaseUser,
  AddressInput,
  CuponGoogleAuth,
  Address,
} from "src/app/interface/user";
import { ProductFeature } from "src/app/interface/shop";

export class Login {
  static readonly type = "[User] Login]";
  constructor(public readonly credentials: { email: any; password: any }) {}
}

export class SignOut {
  static readonly type = "[User] SignOut]";
  constructor() {}
}

export class UpdateUserSession {
  static readonly type = "[User] UpdateUserSession]";
  constructor(
    public readonly info: {
      firebaseUser: FirebaseUser;
      token: string;
      isGhost: string;
      ShowGuide?: boolean;
    }
  ) {}
}

export class CreateProductReview {
  static readonly type = "[User] CreateProductReview]";
  constructor(public readonly review) {}
}

export class CreateBillReview {
  static readonly type = "[User] CreateBillReview]";
  constructor(public readonly review) {}
}

export class FollowPlace {
  static readonly type = "[User] FollowPlace]";
  constructor(public readonly FK_Place) {}
}

export class UnfollowPlace {
  static readonly type = "[User] UnfollowPlace]";
  constructor(public readonly FK_Place) {}
}

export class GoogleLogin {
  static readonly type = "[User] GoogleLogin]";
  constructor() {}
}

export class WatchIdToken {
  static readonly type = "[User] WatchIdToken]";
}

export class FirebaseAuth {
  static readonly type = "[User] FirebaseAuth]";
  constructor(public readonly user: any, public readonly ghostUID?: any) {}
}

export class UserByUID {
  static readonly type = "[User] UserByUID]";
  constructor(public readonly uid: string, public readonly app?: boolean) {}
}

export class ResetPasswordEmail {
  static readonly type = "[User] ResetPasswordEmail]";
  constructor(public readonly email: string) {}
}

export class CreateAcount {
  static readonly type = "[User] CreateAcount]";
  constructor(public readonly user: any) {}
}
export class CreateAccountClient {
  static readonly type = "[User] CreateAccountClient]";
  constructor(public readonly user: any) {}
}

export class RegisterPerson {
  static readonly type = "[User] RegisterPerson]";
  constructor(public readonly user: any) {}
}

export class AllCountries {
  static readonly type = "[User] AllCountries]";
}

export class UploadProfileImage {
  static readonly type = "[User] UploadProfileImage]";
  constructor(public readonly file) {}
}

export class SaveImageRouteForProfile {
  static readonly type = "[User] SaveImageRouteForProfile]";
  constructor(
    public fileRef: Observable<any>,
    public readonly FirebaseId: string
  ) {}
}

export class UpdateProfileImage {
  static readonly type = "[User] UpdateProfileImage]";
  constructor(public readonly Route_File, public readonly FirebaseId) {}
}

export class SaveImageRoute {
  static readonly type = "[User] SaveImageRoute]";
  constructor(
    public readonly fileRef: Observable<any>,
    public readonly FK_ProductFeature: any,
    public readonly FK_ProductPromotion: any,
    public readonly FK_InventoryDetail: string,
    public readonly FirebaseId: String
  ) {}
}

export class DisableShowGuide {
  static readonly type = "[User] DisableShowGuide]";
  constructor(public readonly ShowGuide: boolean) {}
}

export class ProductsFeaturesByUpdate {
  static readonly type = "[User] ProductsFeaturesByUpdate]";
}

export class ProductsFeaturesRelated {
  static readonly type = "[User] Query ProductsFeaturesRelated]";
  constructor(public readonly tags: string[]) {}
}

export class MoreProductsFeaturesRelated {
  static readonly type = "[User] Query MoreProductsFeaturesRelated]";
  constructor(public readonly tags: string[]) {}
}

export class ProductsFeaturesRandom {
  static readonly type = "[User] ProductsFeaturesRandom]";
}

export class GetBillsByUserAndState {
  static readonly type = "[Bill] getBillsByUserStateAndPlace]";
  constructor(public readonly states, public readonly type) {}
}

export class ProgressByBillId {
  static readonly type = "[User] ProgressByBillId]";
  constructor(public readonly id?: string) {}
}

export class AddToCart {
  static readonly type = "[User] AddToCart";
  constructor(
    public readonly detail: ProductFeature,
    public readonly cb?: any
  ) {}
}

export class ReduceToCart {
  static readonly type = "[User] ReduceToCart";
  constructor(
    public readonly detail: ProductFeature,
    public readonly cb?: any
  ) {}
}
export class ClearCart {
  static readonly type = "[User] ClearCart";
}

export class CreateAddress {
  static readonly type = "[Address] CreateAddress]";
  constructor(public readonly address: AddressInput) {}
}

export class UpdateAddress {
  static readonly type = "[Address] updateAddress]";
  constructor(public readonly id, public readonly address) {}
}

export class UpdateBillDetailQuantity {
  static readonly type = "[Address] UpdateBillDetailQuantity]";
  constructor(public readonly newQuantity, public readonly detail) {}
}

export class GetCoins {
  static readonly type = "[User] GetCoins]";
}

export class IsBillAvailable {
  static readonly type = "[User] IsBillAvailable]";
  constructor(public readonly id: string) {}
}

export class MyCart {
  static readonly type = "[User] MyCart]";
}

export class PayCart {
  static readonly type = "[User] PayCart]";
  constructor(public readonly cart: any) {}
}

export class UpdateAddressState {
  static readonly type = "[User] UpdateAddressState]";
  constructor(public readonly id, public readonly State_Address) {}
}

export class CreateNotAvailableAddressToSend {
  static readonly type = "[User] Mutation CreateNotAvailableAddressToSend]";
  constructor(public readonly notAvailable) {}
}

export class MoreProductsFeaturesByUpdate {
  static readonly type = "[User] MoreProductsFeaturesByUpdate]";
}

export class ApproveOrder {
  static readonly type = "[User] ApproveOrder]";
  constructor(public readonly firebaseId: string) {}
}

export class GetCuponsByGoogleAuth {
  static readonly type = "[User] GetCuponsByGoogleAuth]";
}

export class SelectCupon {
  static readonly type = "[User] SelectCupon]";
  constructor(public readonly selectedCupon: CuponGoogleAuth) {}
}

export class SubtractCupon {
  static readonly type = "[User] SubtractCupon]";
}

export class UpdateUserPhone {
  static readonly type = "[User] UpdateUserPhone]";
  constructor(public readonly newPhone: string) {}
}

export class EditBillDetails {
  static readonly type = "[User] EditBillDetails]";
  constructor(
    public readonly billId: string,
    public readonly newDetails: string
  ) {}
}

export class SelectAddress {
  static readonly type = "[User] SelectAddress]";
  constructor(public readonly AddressSelected: Address) {}
}

export class UserMessage {
  static readonly type = "[User] UserMessage]";
  constructor(
    public readonly message: string,
    public readonly button: string,
    public readonly duration: number
  ) {}
}

export class LoginNative {
  static readonly type = "[User] LoginNative]";
  constructor(public readonly conn: any) {}
}

export class NativeUser {
  static readonly type = "[User] NativeUser]";
  constructor(public readonly uid: string) {}
}

export class PredictPlace {
  static readonly type = "[User] PredictPlace]";
  constructor(public readonly query: string) {}
}

export class ThisPlace {
  static readonly type = "[User] ThisPlace]";
}

export class NewUserLaunched {
  static readonly type = "[Bill] NewUserLaunched]";
}
