import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { AngularFireModule } from "@angular/fire/compat";
import { HttpClientModule } from "@angular/common/http";
import { environment } from "../environments/environment";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AngularFirestoreModule } from "@angular/fire/compat/firestore";
import { AngularFireStorageModule } from "@angular/fire/compat/storage";
import { AngularFireAuthModule } from "@angular/fire/compat/auth";
import { NgxsModule } from "@ngxs/store";
import { NgxsReduxDevtoolsPluginModule } from "@ngxs/devtools-plugin";
import { UserState } from "./states/user.state";
import { NgxsRouterPluginModule } from "@ngxs/router-plugin";
import { ServiceWorkerModule } from "@angular/service-worker";
import { LocationService } from "./services/location.service";
import { NgxsLoggerPluginModule } from "@ngxs/logger-plugin";
import { ApolloClientsModule } from "./apollo-clients-module";
import { ApiService } from "./services/api.service";
import { AngularFirePerformanceModule } from "@angular/fire/compat/performance";
import { AngularFireMessagingModule } from "@angular/fire/compat/messaging";
import { UIState } from "./states/ui.state";
import { DeviceDetectorModule } from "ngx-device-detector";
import { ProductsState } from "./states/product.state";
import { PlacesState } from "./states/places.state";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatListModule } from "@angular/material/list";
import { MatBadgeModule } from "@angular/material/badge";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatDialogModule } from "@angular/material/dialog";
import { BasicModule } from "./helpers/basic.module";
import { ComponentsModule } from "./components/components.module";
import {
  AngularFireAnalyticsModule,
  ScreenTrackingService,
  UserTrackingService,
} from "@angular/fire/compat/analytics";
import { UserDeviceState } from "./states/device.state";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { ApolloModule } from "apollo-angular";
import { MiniCartComponent } from "./pages/cart/mini-cart/mini-cart.component";
import { MatCardModule } from "@angular/material/card";
import { FormsModule } from "@angular/forms";
import { CookieService } from "ngx-cookie-service";
import { initializeApp, provideFirebaseApp } from "@angular/fire/app";
import { getAuth, provideAuth } from "@angular/fire/auth";
import { provideFirestore, getFirestore } from "@angular/fire/firestore";
import { provideStorage, getStorage } from "@angular/fire/storage";

@NgModule({
  declarations: [AppComponent, MiniCartComponent],
  imports: [
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    provideFirestore(() => getFirestore()),
    provideStorage(() => getStorage()),
    MatCheckboxModule,
    BrowserModule,
    DeviceDetectorModule.forRoot(),
    HttpClientModule,
    ApolloClientsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    NgxsRouterPluginModule.forRoot(),
    NgxsModule.forRoot([
      UserState,
      ProductsState,
      UIState,
      PlacesState,
      UserDeviceState,
    ]),
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
    }),
    NgxsLoggerPluginModule.forRoot(),
    AngularFirePerformanceModule,
    AngularFireMessagingModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatBadgeModule,
    MatSnackBarModule,
    MatDialogModule,
    BasicModule,
    ComponentsModule,
    NgxsReduxDevtoolsPluginModule.forRoot(),
    ApolloModule,
    MatCardModule,
    FormsModule,
  ],
  providers: [
    ApiService,
    LocationService,
    ScreenTrackingService,
    UserTrackingService,
    CookieService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
