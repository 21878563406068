import { MatDialogRef } from "@angular/material/dialog";
import { Tag } from "src/app/interface/product";
import { ProductFeature } from "src/app/interface/shop";

export class FindProductFeatures {
  static readonly type = "[AllProducts] Query FindProductFeatures]";
  constructor() {}
}

export class RootTags {
  static readonly type = "[Tags] Query RootTags]";
}

export class CategoriesTags {
  static readonly type = "[Tags] Query CategoriesTags]";
  constructor(public readonly id: string) {}
}

export class CategoriesTagsClient {
  static readonly type = "[Tags] Query CategoriesTagsClient]";
  constructor(public readonly id: string) {}
}

export class DeleteSearchCriteriaUser {
  static readonly type = "[Tags] Query DeleteSearchCriteriaUser]";
  constructor(public readonly id: string) {}
}

export class UpdateTag {
  static readonly type = "[Tags] Query UpdateTag]";
  constructor(public readonly tagUpdate: any) {}
}

export class AddTag {
  static readonly type = "[Tags] Query AddTag]";
  constructor(public readonly tag: any) {}
}

export class AddSonTag {
  static readonly type = "[Tags] Mutation AddSonTag]";
  constructor(public readonly tag: Tag, public readonly FK_FatherTag: number) {}
}

export class AddCategoryTag {
  static readonly type = "[Tags] Mutation AddCategoryTag]";
  constructor(public readonly tagTree: any) {}
}

export class TagsByFather {
  static readonly type = "[Tags] Query TagsByFather]";
  constructor(public readonly father: any, public readonly isAdmin: boolean) {}
}

export class CleanFilteredFeatures {
  static readonly type = "CleanFilteredFeatures]";
}

export class MuyTicoCategories {
  static readonly type = "[Tags] Query MuyTicoCategories]";
}

export class ProductsByTag {
  static readonly type = "[Products] Query ProductsByTag]";
  constructor(public readonly tag: string, public readonly reset?: boolean) {}
}

export class ProductsByTags {
  static readonly type = "[Products] Query ProductsByTags]";
  constructor(public readonly tags: string[]) {}
}

export class TagCategories {
  static readonly type = "[Tags] Query TagCategories]";
}

export class TagsByCategory {
  static readonly type = "[Tags] Query TagsByCategory]";
  constructor(public readonly id: string) {}
}

export class FilterByRadius {
  static readonly type = "[Tags] Query FilterByRadius]";
  constructor() {}
}

export class UpdateFeaturesInSearch {
  static readonly type = "[Products] Query UpdateFeaturesInSearch]";
  constructor(public readonly id: string, public readonly quantity?: number) {}
}

export class UpdateSelectedFeature {
  static readonly type = "[Products] Query UpdateSelectedFeature]";
  constructor(public readonly id: string, public readonly quantity?: number) {}
}

export class SelectProductToWatch {
  static readonly type = "[Products] Mutation SelectProductToWatch]";
  constructor(public readonly id) {}
}

export class GetTagsForMarketSearch {
  static readonly type = "[Tags] Query GetTagsForMarketSearch]";
  constructor(public readonly criteria) {}
}

export class FindSearchCriteria {
  static readonly type = "[Find] Query FindSearchCriteria]";
  constructor(public readonly criteria) {}
}

export class ResetFinds {
  static readonly type = "[Find] Query ResetFinds]";
  constructor() {}
}

export class AddCriteriaSearch {
  static readonly type = "[Product] AddCriteriaSearch]";
  constructor(public readonly criteria) {}
}

export class SearchProductsFromState {
  static readonly type = "[Product] Mutation SearchProductsFromState]";
  constructor() {}
}

export class CreateSearchCriteria {
  static readonly type = "[Product] Mutation CreateSearchCriteria]";
  constructor() {}
}

export class GetProductFeatureById {
  static readonly type = "[Product] GetProductFeatureById]";
  constructor(public readonly id) {}
}

export class AddClickToFeature {
  static readonly type = "[Product] AddClickToFeature]";
  constructor(public readonly feature) {}
}

export class SetMetaFeature {
  static readonly type = "[Product] SetMetaFeature]";
  constructor(public readonly feature: ProductFeature) {}
}

export class SetProductTag {
  static readonly type = "[Product] SetProductTag]";
  constructor(public readonly tag: Tag) {}
}

export class SetFeatureTag {
  static readonly type = "[Product] SetFeatureTag]";
  constructor(public readonly tag: Tag) {}
}

export class SetFeatureTags {
  static readonly type = "[Product] SetFeatureTags]";
  constructor(public readonly tags: Tag[]) {}
}

export class SetFilterPrice {
  static readonly type = "[Product] SetFilterPrice]";
  constructor(public readonly PriceOrder: any) {}
}

export class FetchCategoryTags {
  static readonly type = "[Tags] Fetch Category Tags";
}
