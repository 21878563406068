import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { SnackbarComponent } from "../components/snackbar/snackbar.component";

@Injectable({
  providedIn: "root",
})
export class AlertService {
  constructor(private _snackBar: MatSnackBar) {}

  public openSnackBar(message: string, snackType: string = "success") {
    this._snackBar.openFromComponent(SnackbarComponent, {
      duration: 3000,
      horizontalPosition: "end",
      verticalPosition: "top",
      panelClass: [this.getClass(snackType)],
      data: {
        message: message,
        snackType: snackType,
        snackBar: this._snackBar,
      },
    });
  }
  private getClass(type: string): string {
    switch (type) {
      case "success": {
        return "snackbar-success";
      }
      case "warn": {
        return "snackbar-warning";
      }
      case "error": {
        return "snackbar-error";
      }
    }
  }
}
