import { Component, Inject, OnInit } from "@angular/core";
import { MAT_SNACK_BAR_DATA } from "@angular/material/snack-bar";
import { DataAlert } from "src/app/interface/ui";

@Component({
  selector: "app-snackbar",
  templateUrl: "./snackbar.component.html",
  styleUrls: ["./snackbar.component.scss"],
})
export class SnackbarComponent implements OnInit {
  icon: string;
  type: string;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: DataAlert) {}

  ngOnInit() {
    this.type = this.data.snackType;
    switch (this.data.snackType) {
      case "success":
        this.icon = "check_circle_outline";
        break;
      case "error":
        this.icon = "error_outline";
        break;
      case "warn":
        this.icon = "warning";
        break;
    }
  }

  closeSnackbar() {
    this.data.snackBar.dismiss();
  }
}
